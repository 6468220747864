
const PATIENTS_BASE = 58285714;
const PATIENTS_BASE_DATE = new Date(2023, 0, 1);
const SECONDS_PER_PATIENT = 12;

export default {
  data() {
    return {
      patientsCount: 0,
      counterInterval: null,
    };
  },
  computed: {
    digits() {
      return this.patientsCount.toString().length;
    },
  },
  mounted() {
    this.counterInterval = setInterval(() => {
      this.updatePatientsCount();
    }, 1000);
    this.updatePatientsCount();
  },
  beforeDestroy() {
    clearInterval(this.counterInterval);
  },
  methods: {
    updatePatientsCount() {
      const seconds = Math.floor((new Date() - PATIENTS_BASE_DATE) / 1000);
      this.patientsCount = Math.round(PATIENTS_BASE + (seconds / SECONDS_PER_PATIENT));
    },
  },
};
