import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4457cc4d&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHero: require('/vercel/path0/frontend/components/home/HomeHero.vue').default,HomeProblems: require('/vercel/path0/frontend/components/home/HomeProblems.vue').default,HomePatients: require('/vercel/path0/frontend/components/home/HomePatients.vue').default,HomeProducts: require('/vercel/path0/frontend/components/home/HomeProducts.vue').default,HomePartnership: require('/vercel/path0/frontend/components/home/HomePartnership.vue').default})
